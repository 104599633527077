import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from '../utils/mediaQueries'

const Wrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  padding: 80px 100px 80px 100px;

  ${ media.medium`
    padding: 30px;
  ` }
`

function PageWrapper ({ children }) {
  return (
    <Wrapper>{children}</Wrapper>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageWrapper
