import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  margin-bottom: 2.5rem;
`

const PageDescription = ({ title, text }) => {
  return (
    <Container>
      {title && <h1>{title}</h1>}
      {text && <p>{text}</p>}
    </Container>
  )
}

PageDescription.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export default PageDescription
