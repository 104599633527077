import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { media } from '../utils/mediaQueries'

const Link = styled.button`
  display: block;
  width: 225px;
  margin: 40px auto;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 2px solid #444444;
  border-radius: 2px;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.07rem;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  transition: border 200ms ease-in-out, color 200ms ease-in-out, background-color 200ms ease-in-out;
  outline: none;

  ${ media.medium`
    margin: 20px auto;
  ` }

  &:hover {
    border: 2px solid #6C82FF;
    transition: border 200ms ease-in-out, color 200ms ease-in-out, background-color 200ms ease-in-out;
    color: #6C82FF;

    a {
      color: #6C82FF;
    }
  }

  &:active {
    color: white;
    border: 2px solid #6C82FF;
    background-color: #6C82FF;
    transition: border 200ms ease-in-out, color 200ms ease-in-out, background-color 200ms ease-in-out;

    a {
      color: white;
    }
  }
`

const Button = ({ children, className }) => (
  <Link className={className}>{children}</Link>
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Button
