import React from 'react'
import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'
import { media } from '../utils/mediaQueries'

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 0;
  padding-bottom: 45%;
  margin-top: 100px;

  ${ media.medium`
    margin-top: 70px;
  ` }
`

const HeroImage = ({ media }) => (
  <Image fluid={media.fluid} critical={true} alt={media.alt}/>
)

HeroImage.propTypes = {
  fluid: PropTypes.object,
}

export default HeroImage
