import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

const LinkWrapper = ({ to, children }) => {
  if (to) {
    return (
      <GatsbyLink to={to}>
        {children}
      </GatsbyLink>
    )
  } else {
    return children
  }
}

LinkWrapper.propTypes = {
  to: PropTypes.string,
  children: PropTypes.object,
}

export default LinkWrapper
