import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { media } from '../utils/mediaQueries'
import LogoImage from './logo'
import Button from './button'

const Container = styled.footer`
  height: 200px;
  width: 100%;
  padding-left: 100px;
  padding-right: 100px;
  background: #D8D8D8;
  display: flex;
  align-items: center;

  ${ media.medium`
    padding-left: 30px;
    padding-right: 30px;
    height: initial;
    font-size: 0.8rem;
  ` }
`
const Logo = styled(LogoImage)`
  width: 80px;
  height: 100%;
  margin-right: 15px;

  img {
    width: 80px;
  }

  ${ media.medium`
    width: 28px;
    height: 100%;
    margin-top: 25px;
    margin-bottom: 25px;

    img {
      width: 28px;
    }
  ` }
`

const Text = styled.div`
  white-space: pre-wrap;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CoookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  color: #444;
  padding: 20px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -3px 30px 1px rgba(0, 0, 0, 0.1);

  p {
    margin-right: 20px;
  }

  a {
    color: #444;
    text-decoration: underline;
  }
`

const CookieButton = styled(Button)`
  width: initial;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  margin-left: auto;
  z-index: 1;
`

const Footer = () => {
  const [showCookie, toggleCookie] = useState(checkCookies())

  const { datoCmsFooter: { contact } } = useStaticQuery(graphql`
    query ContactQuery {
      datoCmsFooter {
        contact
      }
    }
  `)

  function onCookieBannerClick () {
    window.localStorage.userAcceptsCookies = 1
    toggleCookie(false)
  }

  return (
    <>
      <Container>
        <Link to='/'>
          <Logo/>
        </Link>
        <Text
          dangerouslySetInnerHTML={{ __html: contact }}
        ></Text>
      </Container>
      {showCookie &&
        <CoookieBanner>
          <p>We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it. <Link to="/privacy"> Read privacy policy</Link></p>
          <CookieButton>
            <div onClick={onCookieBannerClick}>OK</div>
          </CookieButton>
        </CoookieBanner>
      }
    </>
  )
}

function checkCookies () {
  if (typeof window !== 'undefined') {
    return window.localStorage.userAcceptsCookies !== '1'
  }

  return false
}

export default Footer
