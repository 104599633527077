import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import LinkWrapper from './linkWrapper'
import { media } from '../utils/mediaQueries'

const Container = styled.div`
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: flex;

  ${ media.medium`
    flex-direction: column;
  ` }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const MediaWrapper = styled.div`
  ${ media.medium`
    margin-left: -30px;
    margin-right: -30px;
  ` }
`

const Image = styled(GatsbyImage)`
  flex: 1 0 auto;
  width: 400px;
  margin-right: 20px;

  ${ media.medium`
    width: 100%;

    > div {
      padding-bottom: 70% !important;
    }
  ` }

  ${ ({ to }) => to && css`
    img {
      transition: opacity 0.5s ease 0s, transform 500ms !important;
    }

    &:hover {
      img {
        transform: scale(1.01);
        transition: opacity 0.5s ease 0s, transform 500ms !important;
      }
    }
  ` }
`

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${ media.medium`
    padding: 2.5rem 0 0 0;
  ` }
`

const Header = styled.h3`
  margin-bottom: 1.25rem;
`

const Text = styled.div`
  font-size: 0.875rem;
  white-space: pre-wrap;
`

const CallToAction = styled(GatsbyLink)`
  margin-top: 15px;
  text-decoration: underline;
`

const CardSmall = ({ title, media, text, callToAction, to }) => {
  let image

  if (media && media.fluid) {
    image = <Image to={to} fluid={media.fluid} style={{ display: 'block' }} alt={media.alt}/>
  } else {
    image = null
  }

  return (
    <Container>
      <LinkWrapper to={to}>
        <MediaWrapper>
          {image}
        </MediaWrapper>
      </LinkWrapper>
      <TextContainer>
        <LinkWrapper to={to}>
          <Header>{title}</Header>
        </LinkWrapper>
        <Text>{text}</Text>
        {callToAction && to &&
          <CallToAction to={to}>{callToAction}</CallToAction>
        }
      </TextContainer>
    </Container>
  )
}

CardSmall.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  text: PropTypes.string,
  to: PropTypes.string,
  callToAction: PropTypes.string,
}

export default CardSmall
