import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactCalendar from 'react-calendar'

import config from '../../config/website'

const FullWidthCalendar = styled(ReactCalendar)`
  width: 100% !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  border: none !important;
  background: initial !important;
  font-size: 0.875rem !important;

  .react-calendar__navigation,
  .react-calendar__month-view {
    background: white;
  }

  .react-calendar__navigation {
    margin-bottom: 0.25em !important;
  }

  abbr{
    cursor: pointer !important;
    border-bottom: none !important;
  }

  .react-calendar__navigation__arrow {
    font-size: 26px;
  }

  .react-calendar__tile--active {
    background: rgba(108,130,255,0.7) !important; 
    color: white !important;
  }

  button {
    /* border-radius: 3px; */
  }

  .react-calendar__month-view__days__day--weekend {
    color: #444444 !important;
  }

  .react-calendar__month-view__days__day--weekend:disabled {
    color: graytext !important;
  }

  .react-calendar__month-view__days__day--weekend.react-calendar__tile--active {
    color: white !important;
  }

  .react-calendar__month-view__weekdays {
    /* display: none !important; */
    font-weight: 400 !important;
  }

  .react-calendar__tile--rangeStart {
    border-radius: 3px 0 0 3px;
    background: rgb(108,130,255) !important; 
  }

  .react-calendar__tile--rangeEnd {
    border-radius: 0 3px 3px 0;
    background: rgb(108,130,255) !important; 
  }

  .react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
    border-radius: 3px;
  }

  .react-calendar__navigation button[disabled] {
    background-color: initial !important;
    color: initial !important;
  }
`

class Calendar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      dates: []
    }
  }

  componentDidMount () {
    fetch(config.siteUrl + '/.netlify/functions/fetch?name=' + this.props.yacht.toLowerCase())
    // fetch('http://localhost:9000/fetch?name=' + this.props.yacht.toLowerCase())
      .then(
        response => {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' +
              response.status)
            return
          }
          // Examine the text in the response
          response.json().then(data => {
            this.setState({
              dates: convertToTimestamp(data)
            })
          })
        }
      )
      .catch(err => {
        console.log('Fetch Error:', err)
      })
  }

  checkDisabled = ({ activeStartDate, date, view }) => {
    if (this.state.dates.length === 0) {
      return false
    }
    const currentDate = new Date(date).getTime()
    let disabled = false

    this.state.dates.forEach(range => {
      if (range[0] <= currentDate && currentDate <= range[1]) {
        disabled = true
      }
    })

    return disabled
  }

  render () {
    const { onChange } = this.props

    return (
      <FullWidthCalendar
        next2Label={null}
        prev2Label={null}
        showNeighboringMonth={false}
        minDate={getCalendarLimits().min}
        maxDate={getCalendarLimits().max}
        minDetail={'month'}
        selectRange={true}
        tileDisabled={this.checkDisabled}
        onChange={onChange}
        onClickDay={onChange}
      />
    )
  }
}

function convertToTimestamp (array) {
  return array.map(range => range.map(date => new Date(date).getTime()))
}

function getCalendarLimits () {
  let today = new Date()
  return {
    min: new Date(`${ today.getFullYear() }-${ today.getMonth() + 1 }`),
    max: new Date(`${ today.getFullYear() + 1 }-${ today.getMonth() + 2 }`),
  }
}

Calendar.propTypes = {
  yacht: PropTypes.string,
  onChange: PropTypes.func,
}

export default Calendar
