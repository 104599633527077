import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from './button'
import { media } from '../utils/mediaQueries'

const FormContainer = styled.form`
  position: relative;
`

const FormBody = styled.div`
  display: flex;
  flex-direction: column;
`

const EmailInput = styled.input`
  display: block;
  border: none;
  height: 44px;
  padding: 10px;
  margin-bottom: 4px;
  border-radius: 2px;

  &:focus {
    outline: none;
  }
`

const TextInput = styled.textarea`
  display: block;
  border: none;
  min-height: 205px;
  height: 100%;
  padding: 10px;
  border-radius: 2px;

  &:focus {
    outline: none;
  }
`

const HoneyPot = styled.div`
  visibility:hidden;
  opacity: 0;
  display: none;
`

const SubmitButton = styled(Button)`
  margin-right: 0;
  margin-left: auto;

  ${ media.medium`
    margin-right: auto;
  ` }
`

const ErrorMessage = styled.p`
  color: #6C82FF;
  position: absolute;
  right: 0;
  margin-top: 7px;

  ${ media.medium`
    bottom: -5px;
    width: 100%;
    text-align: center;
  ` }
`

class Form extends Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      message: '',
      feedbackMessage: ''
    }
  }

  componentDidMount () {

  }

  handleChange = e => {
    this.setState({ [e.target.name.toLowerCase()]: e.target.value })
  };

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target

    if (!this.state.email || this.state.email === '') {
      this.setState({
        feedbackMessage: 'Please enter a valid email'
      })
    } else {
      this.setState({
        feedbackMessage: ''
      }, () => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': form.getAttribute('name'),
            'Email': this.state.email,
            'Yacht': this.props.yacht,
            'Selected start date': this.props.selectedStartDate,
            'Selected end date': this.props.selectedEndDate,
            'Message': this.state.message,
          })
        })
          .then(() => {
            this.setState({
              feedbackMessage: 'Thank you for your inquiry',
              email: '',
              message: ''
            })

            this.props.resetCalendar()
          })
          .catch(error => {
            this.setState({
              feedbackMessage: error
            })
          })
      })
    }
  };

  render () {
    const { selectedStartDate, selectedEndDate, yacht } = this.props
    const { email, message, feedbackMessage } = this.state

    return (
      <FormContainer
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <FormBody>
          <input type="hidden" name="form-name" value="contact" />
          <HoneyPot>
            <input name="bot-field" onChange={this.handleChange}/>
          </HoneyPot>
          <EmailInput type="email" name="Email" placeholder="Your email" value={email} onChange={this.handleChange}/>
          <TextInput type="text" name="Message" placeholder="Additional requests" value={message} onChange={this.handleChange}/>
          <input type="hidden" name="Selected start date" value={selectedStartDate}/>
          <input type="hidden" name="Selected end date" value={selectedEndDate}/>
          <input type="hidden" name="Yacht" value={yacht}/>
        </FormBody>
        <ErrorMessage>{feedbackMessage}</ErrorMessage>
        <SubmitButton type="submit">Check availability</SubmitButton>
      </FormContainer>
    )
  }
}

function encode (data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

Form.propTypes = {
  selectedStartDate: PropTypes.string,
  selectedEndDate: PropTypes.string,
  yacht: PropTypes.string,
  resetCalendar: PropTypes.func,
}

export default Form
