import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'

import slugify from '../utils/slugify'
import { media } from '../utils/mediaQueries'

const Container = styled.div`
  width: 100%;
  display: flex;

  h3 {
    min-height: 3.3rem;
  }

  ${ media.medium`
    flex-direction: column;
    min-height: initial;
  ` }
`

const Link = styled(GatsbyLink)`
  flex: 1 0 50%;
  max-width: 50%;
  margin-right: 40px;

  &:last-child {
    margin-right: 0;

    ${ media.medium`
      margin-bottom: 0;
    ` }
  }

  ${ media.medium`
    margin-right: 0;
    margin-bottom: 40px;
    flex: initial;
    width: 100%;
    max-width: initial;
  ` }
`

const Image = styled(GatsbyImage)`
  height: 300px;

  ${ media.medium`
    height: 0;
    padding-bottom: 60%;
    width: 100%;
  ` }

  img {
    transition: opacity 0.5s ease 0s, transform 500ms !important;
  }

  &:hover {
    img {
      transform: scale(1.01);
      transition: opacity 0.5s ease 0s, transform 500ms !important;
    }
  }
`

const Other = ({ title, to, items }) => (
  <>
    <h2>{title}</h2>
    <Container>
      {items.map(({ originalId, name, media }) => (
        <Link key={originalId} to={`${ to }/${ slugify(name) }`}>
          <h3>{name}</h3>
          <Image
            fluid={(Array.isArray(media) && media.length > 0) ? media[0].fluid : media.fluid}
            alt={(Array.isArray(media) && media.length > 0) ? media[0].alt : media.alt}
          />
        </Link>
      ))}
    </Container>
  </>
)

Other.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default Other
