import React from 'react'
import PropTypes from 'prop-types'
import GatsbyImage from 'gatsby-image'
import { Carousel as ResponsiveCarousel } from 'react-responsive-carousel'
import styled from 'styled-components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { media } from '../utils/mediaQueries'
import ArrowLeft from '../images/inline/arrow-left.svg'
import ArrowRight from '../images/inline/arrow-right.svg'

const CustomCarousel = styled(ResponsiveCarousel)`
  .control-arrow:before {
    display: none !important;
    content: none !important;
  }

  .control-arrow {
    padding: 0 !important;
    width: 100px !important;
    background: initial !important;
    background-image: url(${ ArrowRight }) !important;
    background-repeat: no-repeat !important;
  }

  .control-arrow.control-next {
    background-image: url(${ ArrowRight }) !important;
    background-position: 80% center !important;
  }

  .control-arrow.control-prev {
    background-image: url(${ ArrowLeft }) !important;
    background-position: 20% center !important;
  }

  ${ media.medium`
    .control-arrow {
      width: 20px !important;
    }

    .control-arrow.control-next,
    .control-arrow.control-prev {
      background-position: center center !important;
    }
  ` }

  .control-dots .dot {
    width: 6px !important;
    height: 6px !important;
    opacity: 1 !important;
    box-shadow: none !important;
    transition: transform 400ms ease-in !important;
    margin: 0 7px !important;
  }

  .control-dots .dot.selected {
    transform: scale(1.6) !important;
    transition: transform 400ms ease-in !important;
  }
`

const Carousel = ({ media }) => (
  <CustomCarousel
    showThumbs={false}
    showArros={true}
    infiniteLoop={true}
    swipeable={true}
    showStatus={false}
  >
    {media.map(({ fluid, id, alt }) => (
      <GatsbyImage key={id} fluid={fluid} alt={alt}/>
    ))}
  </CustomCarousel>
)

Carousel.propTypes = {
  media: PropTypes.array,
}

export default Carousel
