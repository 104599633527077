import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Calendar from './calendar'
import Form from './form'
import { media } from '../utils/mediaQueries'

const Container = styled.div`
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
`

const ContainerInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  ${ media.medium`
    flex-direction: column;
  ` }

  > div, > form {
    flex: 1 0 auto;
    max-width: calc(50% - 10px);
    
    ${ media.medium`
      max-width: 100%;
      
      :first-child {
        margin-bottom: 20px;
      }
    ` }
  }
`

class Contact extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedStartDate: '',
      selectedEndDate: ''
    }
  }

  onCalendarChange = date => {
    if (Array.isArray(date)) {
      this.setState({
        selectedStartDate: date[0].toLocaleDateString('en-US'),
        selectedEndDate: date[1].toLocaleDateString('en-US')
      })
    } else {
      this.setState({
        selectedStartDate: date.toLocaleDateString('en-US'),
        selectedEndDate: '',
      })
    }
  }

  resetCalendar = () => {
    this.setState({
      selectedStartDate: '',
      selectedEndDate: ''
    })
  }

  render () {
    const { yacht } = this.props
    const { selectedStartDate, selectedEndDate } = this.state

    return (
      <Container>
        <p>Fill in the form below and one of our agents will get back to you in the next 24 hours</p>
        <ContainerInner>
          <Calendar
            yacht={yacht}
            onChange={this.onCalendarChange}
          />
          <Form
            yacht={yacht}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            resetCalendar={this.resetCalendar}
          />
        </ContainerInner>
      </Container>
    )
  }
}

Contact.propTypes = {
  yacht: PropTypes.string,
}

export default Contact
