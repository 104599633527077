import React, { Component } from 'react'
import { Link as GatsbyLink, graphql } from 'gatsby'
import styled from 'styled-components'

import PageWrapper from '../components/pageWrapper'
import PageDescription from '../components/pageDescription'
import HeroImage from '../components/heroImage'
import Carousel from '../components/carousel'
import Contact from '../components/contact'
import CardSmall from '../components/cardSmall'
import Other from '../components/other'
import Footer from '../components/footer'

import slugify from '../utils/slugify'
import { media } from '../utils/mediaQueries'

const Breadcrumbs = styled.div`
  margin-bottom: 40px;
  color: #6C82FF;
  font-weight: 600;

  a {
    color: #6C82FF;
  }
`

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  ${ media.medium`
    flex-direction: column;
  ` }

  > div {
    flex: 1 0 auto;
    max-width: calc(50% - 10px);
    
    ${ media.medium`
      max-width: 100%;
      
      :first-child {
        margin-bottom: 20px;
      }
    ` }
  }
`

const CarouselContainer = styled.div`
  ${ media.medium`
    margin-left: -30px;
    margin-right: -30px;
  ` }
`

const Info = styled.div`
  white-space: pre-wrap;
  line-height: 1.8;
`

class YachtPage extends Component {
  render () {
    const {
      pageContext: { originalId },
      data: { datoCmsYachtsPage: { yachts, heroImage } }
    } = this.props

    const currentYacht = yachts.find(yacht => yacht.originalId === originalId)
    const otherYachts = yachts.filter(yacht => yacht.originalId !== originalId)

    const { name, description, details, media, teamMembers, food, amenities } = currentYacht

    return (
      <>
        <HeroImage media={heroImage}/>
        <PageWrapper>
          <Breadcrumbs>
            <GatsbyLink to='/yachts'>Yachts</GatsbyLink>
            {' > '}
            <GatsbyLink to={`/yachts/${ slugify(name) }`}>{name}</GatsbyLink>
          </Breadcrumbs>
          <PageDescription title={name} text={description}/>
          <CarouselContainer>
            <Carousel media={media}/>
          </CarouselContainer>
          <InfoContainer>
            <Info>
              <h3>Info</h3>
              <p>{details}</p>
            </Info>
            <Info>
              <h3>Amenities</h3>
              <p>{amenities}</p>
            </Info>
          </InfoContainer>
          <Contact yacht={name}/>
          <h2>Team</h2>
          {teamMembers.map(({ name, media, about, originalId }) => (
            <CardSmall key={originalId} title={name} text={about} media={media}/>
          ))}
          <h2>Food Menu</h2>
          {food.map(({ name, media, about, originalId }) => (
            <CardSmall key={originalId} title={name} text={about} media={media}/>
          ))}
          <Other title='Other Yachts' to='/yachts' items={otherYachts}/>
        </PageWrapper>
        <Footer/>
      </>
    )
  }
}

export const query = graphql`
  query yachtPage {
    datoCmsYachtsPage {
      heroImage {
        alt
        fluid(maxWidth: 1400, maxHeight: 630, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      yachts {
        name
        description
        details
        amenities
        originalId
        media {
          id
          alt
          fluid(maxWidth: 800, maxHeight: 520, imgixParams: {
            fm: "jpg",
            auto: "compress,format",
            fit: "crop",
            crop: "faces"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
        teamMembers {
          originalId
          name
          about
          media {
            id
            alt
            fluid(maxWidth: 400, maxHeight: 400, imgixParams: {
              fm: "jpg",
              auto: "compress,format",
              fit: "crop",
              crop: "faces"
            }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        food {
          originalId
          name
          about
          media {
            id
            alt
            fluid(maxWidth: 400, maxHeight: 400, imgixParams: {
              fm: "jpg",
              auto: "compress,format",
              fit: "crop",
              crop: "faces"
            }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default YachtPage
